export function cachedFunction(func) {
    const cache = new Map();
    return (input) => {
        if (cache.has(input)) {
            return cache.get(input);
        }
        const result = func(input);
        cache.set(input, result);
        return result;
    };
}
