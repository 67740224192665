export function initSettings() {
    return {
        disable404: false,
        hostname: null,
        period: 'Month',
        targetEndpoint: {
            path: null,
            status: null,
        },
        targetLocation: null,
        hiddenEndpoints: new Set(),
    };
}
