export const SERVER_URL = 'https://www.apianalytics-server.com';
export const columns = [
    'ip_address',
    'path',
    'hostname',
    'user_agent',
    'method',
    'response_time',
    'status',
    'location',
    'user_id',
    'time',
];
export var ColumnIndex;
(function (ColumnIndex) {
    ColumnIndex[ColumnIndex["IPAddress"] = 0] = "IPAddress";
    ColumnIndex[ColumnIndex["Path"] = 1] = "Path";
    ColumnIndex[ColumnIndex["Hostname"] = 2] = "Hostname";
    ColumnIndex[ColumnIndex["UserAgent"] = 3] = "UserAgent";
    ColumnIndex[ColumnIndex["Method"] = 4] = "Method";
    ColumnIndex[ColumnIndex["ResponseTime"] = 5] = "ResponseTime";
    ColumnIndex[ColumnIndex["Status"] = 6] = "Status";
    ColumnIndex[ColumnIndex["Location"] = 7] = "Location";
    ColumnIndex[ColumnIndex["UserID"] = 8] = "UserID";
    ColumnIndex[ColumnIndex["CreatedAt"] = 9] = "CreatedAt";
})(ColumnIndex || (ColumnIndex = {}));
export const graphColors = [
    '#3FCF8E',
    '#5784BA',
    '#EBEB81',
    '#218B82',
    '#FFD6A5',
    '#F9968B',
    '#B1A2CA',
    '#E46161', // Red
];
